import { Options } from 'axios-hooks';
import { CardExtraInfo } from '../model/types/card';
import { useAppAxios } from '/shared/api/axios';
import { TemplateResponse, ErrorResponse } from '/shared/api/type';

export function useGetCardExtraInfo(link: string, options?: Options) {
  return useAppAxios<TemplateResponse<CardExtraInfo>, null, ErrorResponse>(
    `/marker/${link}/extra`,
    options,
  );
}
