import { MapMarkerFeature } from '/entities/Marker';

/**
 * @description проверяет на различие коллекций по id
 *
 * @param oldFeatures текущий
 * @param newFeatures новый
 * @returns MapMarkerFeature[] текущий или новый
 */
export const updateMarkerFeatures = (
  oldFeatures: MapMarkerFeature[],
  newFeatures: MapMarkerFeature[],
): MapMarkerFeature[] | null => {
  if (newFeatures.length) {
    if (!oldFeatures.length) {
      // init & update empty
      // console.log('init & update empty', newFeatures, oldFeatures);
      return newFeatures as MapMarkerFeature[];
    } else {
      // check difference & update
      let diff = false;

      for (let i = 0; i < newFeatures.length; i++) {
        const income = newFeatures[i];
        const has = oldFeatures.find((f) => f.id === income.id);
        if (!has) {
          diff = true;
          break;
        }
      }

      if (diff) {
        // console.log('check difference & update', newFeatures, oldFeatures);
        return newFeatures as MapMarkerFeature[];
      } else {
        return null;
      }
    }
  } else {
    // set empty
    if (oldFeatures.length) {
      // console.log('set empty', oldFeatures);
      return [];
    } else {
      return null;
    }
  }
};
