import { useEffect } from 'react';
import { useGetCard, useGetCardExtraInfo } from '/entities/Card';
import { useCardStore } from '../store/cardStore';
import {
  getFnSetCard,
  getFnSetCardErrorText,
  getFnSetCardExtraInfo,
  getFnSetCardIsError,
  getFnSetCardloading,
} from '../selectors/card';

export const useGetCardService = (link: string) => {
  const setCard = useCardStore(getFnSetCard);
  const setErrorText = useCardStore(getFnSetCardErrorText);
  const setError = useCardStore(getFnSetCardIsError);
  const setLoading = useCardStore(getFnSetCardloading);
  const setExtraInfo = useCardStore(getFnSetCardExtraInfo);

  const [{ error, loading, data }, refetchCard] = useGetCard(link);

  const [{ data: extraData }, refetch] = useGetCardExtraInfo(link);

  useEffect(() => {
    if (data?.data) {
      setCard(data.data);
      setError(false);
      setErrorText(undefined);
    }
  }, [data?.data]);

  useEffect(() => {
    if (extraData?.data) {
      setExtraInfo(extraData.data);
    }
  }, [extraData?.data]);

  useEffect(() => setLoading(loading), [loading]);
  useEffect(() => {
    if (error?.response?.data?.message) {
      setErrorText(error.response.data.message);
    }
    setError(true);
  }, [error]);

  return refetchCard;
};
