import { Nav, Stack } from 'react-bootstrap';
import s from './CardLinks.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import browserIcon from '/shared/assets/icons/browser.svg?react';
import clienyIcon from '/shared/assets/icons/client.svg?react';
import ARIcon from '/shared/assets/icons/AR.svg?react';
import { Icon } from '/shared/ui/Icon';

interface CardLinksProps {
  className?: string;
  browserURL?: string;
  clientrURL?: string;
  ARURL?: string;
}

export const CardLinks = (props: CardLinksProps) => {
  const { className, ARURL, browserURL, clientrURL } = props;

  return (
    <Stack className={classNames(s.CardLinks, {}, [className])} direction='vertical'>
      <p className={s.text}>Войти через:</p>
      <Nav variant='pills' navbar={false} className={s.nav}>
        <Nav.Item>
          <Nav.Link
            href={browserURL ?? ''}
            className={classNames(s.link, { [s.linkDisabled]: !browserURL })}
            active={!!browserURL}
            disabled={!browserURL}
          >
            <Icon Svg={browserIcon} />
            Браузер
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href={clientrURL ?? ''}
            active={!!clientrURL}
            className={classNames(s.link, { [s.linkDisabled]: !clientrURL })}
            disabled={!clientrURL}
          >
            <Icon Svg={clienyIcon} />
            Клиент
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href={ARURL ?? ''}
            active={!!ARURL}
            className={classNames(s.link, { [s.linkDisabled]: !ARURL })}
            disabled={!ARURL}
          >
            <Icon Svg={ARIcon} />
            AR
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Stack>
  );
};
