import { useEffect } from 'react';
import { useGetGeojson } from '/entities/Marker';
import { useClusterSourceStore } from '../store/clusterSourceStore';
import { getFnClusterSourceGeojson } from '../selectors/clusterSourceSelectors';

export const useGetGeojsonService = () => {
  const setGeojson = useClusterSourceStore(getFnClusterSourceGeojson);
  const [{ data }, refetchGeojson] = useGetGeojson();

  useEffect(() => {
    if (data) {
      setGeojson(data);
    }
  }, [data]);

  return refetchGeojson;
};
