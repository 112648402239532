import './Loader.scss';

export const Loader = () => {
  return (
    <div>
      <div className='lds-default'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
