import { memo, useCallback, useState } from 'react';
import s from './CardTagsList.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import moreIcon from '/shared/assets/icons/more tags.svg?react';
import { IconButton } from '/shared/ui/IconButton/IconButton';
import { Icon } from '/shared/ui/Icon';
import { ITag } from '/entities/Tag';

interface CardTagsListProps {
  className?: string;
  tags: ITag[];
}

export const CardTagsList = memo((props: CardTagsListProps) => {
  const { className, tags } = props;
  const [more, setMore] = useState<boolean>(tags.length < 3 || false);

  const onClick = useCallback(() => setMore(true), [more]);

  if (!tags) return null;

  return (
    <div className={classNames(s.CardTagsList, {}, [className])}>
      {more ? (
        tags.map((tag) => (
          <span className={s.tag} key={tag._id}>
            #{tag.name}
          </span>
        ))
      ) : (
        <>
          {tags.slice(0, 3).map((tag) => (
            <span className={s.tag} key={tag._id}>
              #{tag.name}
            </span>
          ))}
          <IconButton onClick={onClick}>
            <Icon Svg={moreIcon} />
          </IconButton>
        </>
      )}
    </div>
  );
});
