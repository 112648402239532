import { InputHTMLAttributes, PropsWithChildren } from 'react';
import s from './IconButton.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';

interface IconButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const IconButton = (props: PropsWithChildren<IconButtonProps>) => {
  const { className, children, ...other } = props;

  return (
    <button className={classNames(s.clear, {}, [className])} {...other} type='button'>
      {children}
    </button>
  );
};
