import { Suspense } from 'react';
import './App.scss';
import { MainLayout } from '/shared/layouts/MainLayout';
import { HeadBar } from '/features/HeadBar/';
import { WebAppRouter } from './providers/router';
import { useFingerprint } from '/shared/hooks/useFingerprint';
import { isMobile } from 'react-device-detect';
import { MobileHeader } from '/features/MobileHeader/';

const App = () => {
  const fp = useFingerprint();

  return (
    <Suspense fallback=''>
      <MainLayout
        content={<WebAppRouter />}
        mobileHeader={isMobile ? <MobileHeader /> : undefined}
        header={!isMobile ? <HeadBar /> : undefined}
      />
    </Suspense>
  );
};

export default App;
