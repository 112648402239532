import React, { memo, useEffect, useState } from 'react';
import { useMapStore } from '../model/store/mapStore';
import { useLocalStorage } from '/shared/hooks/useLocalStorage';
import { getRouteMain } from '/shared/const/router';
import {
  getFnMapInitLonLat,
  getFnMapInitZoom,
  getMapLat,
  getMapLon,
  getMapZoom,
} from '../model/selectors/mapSelectors';

export const MapViewStateSave = memo(() => {
  const lon = useMapStore(getMapLon);
  const lat = useMapStore(getMapLat);
  const zoom = useMapStore(getMapZoom);
  const setInitLonLat = useMapStore(getFnMapInitLonLat);
  const setInitZoom = useMapStore(getFnMapInitZoom);

  const [inited, setInited] = useState(false);

  const [lsMapLon, setLsMapLon] = useLocalStorage('MAP_LN', 0);
  const [lsMapLat, setLsMapLat] = useLocalStorage('MAP_LT', 0);
  const [lsMapZoom, setLsMapZoom] = useLocalStorage('MAP_ZM', 1);

  // восстанавливаем из LocalStorage ViewState только для MainPage
  useEffect(() => {
    let isMainPage = false;

    if (window.location) {
      isMainPage = window.location.pathname == getRouteMain();
    }

    if (isMainPage) {
      const storedlsMapLon = localStorage.getItem('MAP_LN');
      const storedlsMapLat = localStorage.getItem('MAP_LT');
      if (storedlsMapLon && storedlsMapLat) {
        setInitLonLat(JSON.parse(storedlsMapLon), JSON.parse(storedlsMapLat));
      }

      const storedlsMapZoom = localStorage.getItem('MAP_ZM');
      if (storedlsMapZoom) {
        setInitZoom(JSON.parse(storedlsMapZoom));
      }
    }

    setInited(true);
  }, []);

  useEffect(() => {
    if (inited) {
      setLsMapLon(lon);
      setLsMapLat(lat);
    }
  }, [lon, lat, inited]);

  useEffect(() => {
    if (inited) {
      setLsMapZoom(zoom);
    }
  }, [zoom, inited]);

  return null;
});
