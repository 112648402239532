import { Route, Routes } from 'react-router-dom';
import { getRouteMain, getRouteWebMarker } from '/shared/const/router';
import MainPage from '/pages/MainPage';
import { CardSidebar } from '/widgets/CardSidebar/';
import { NotFoundPage } from '/pages/NotFoundPage';
import { memo } from 'react';

export const WebAppRouter = memo(() => {
  return (
    <Routes>
      <Route path={getRouteMain()} element={<MainPage />}>
        <Route path={getRouteWebMarker(':id')} element={<CardSidebar />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
});
