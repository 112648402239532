import { create } from 'zustand';
import { FeatureCollection } from '/entities/Marker';
import { initialGeojson } from '../const/initialGeojson';

/**
 * Хранилище geojson для maplibre
 */

export type ClusterSourceStore = {
  isSourceLoaded: boolean;
  geojson: FeatureCollection;
  setIsSourceLoaded: (isSourceLoaded: boolean) => void;
  setGeojson: (geojson: FeatureCollection) => void;
};

export const useClusterSourceStore = create<ClusterSourceStore>()((set, get) => ({
  isSourceLoaded: false,
  geojson: initialGeojson,
  setIsSourceLoaded: (isSourceLoaded) => set({ isSourceLoaded }),
  setGeojson: (geojson) => set({ geojson }),
}));
