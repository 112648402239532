import axios from 'axios';
import { API_URL } from '/shared/api/rtkApi';
import { makeUseAxios } from 'axios-hooks';

export const api = axios.create({
  baseURL: `${API_URL}`,
  withCredentials: true,
});

// api.interceptors.request.use((config) => {
//   if (config.) {
//   }
//   return config;
// });

export const useAppAxios = makeUseAxios({ axios: api, cache: false });
