export enum AppRoutes {
  MAIN = 'main',
  // MARKER = 'marker',
  // ADMIN = 'admin',
  NOT_FOUND = 'not_found', // last
}

export enum WebMapRoutes {
  MAIN = 'main',
  MARKER = 'marker',
}

export const getRouteMain = () => '/';
export const getRouteMainClient = () => '/c/';
export const getRouteWebMarker = (id: string) => `/w/${id}`;
export const getRouteAdmin = () => '/admin';

export const AppRouteByPathPattern: Record<string, AppRoutes> = {
  [getRouteMain()]: AppRoutes.MAIN,
  // [getRouteAdmin()]: AppRoutes.ADMIN,
};

export const WebMapRouteByPathPattern: Record<string, WebMapRoutes> = {
  [getRouteMain()]: WebMapRoutes.MAIN,
  [getRouteWebMarker(':id')]: WebMapRoutes.MARKER,
};
