import React, { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import s from './SideBar.module.scss';
import closeIcon from '/shared/assets/icons/arrow to left.svg?react';
import { Icon } from '/shared/ui/Icon';

interface SideBarProps {
  onClose?: () => void;
}

export const SideBar = ({ children, onClose }: PropsWithChildren<SideBarProps>) => {
  return (
    <aside className={s.SideBar}>
      {children}
      <Button className={s.close} variant='light' title='закрыть' onClick={onClose}>
        <Icon Svg={closeIcon} />
      </Button>
    </aside>
  );
};
