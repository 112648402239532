import React from 'react';
import { RouteProps } from 'react-router-dom';
import { AppRoutes, getRouteAdmin, getRouteMain, getRouteWebMarker } from '/shared/const/router';
import { NotFoundPage } from '/pages/NotFoundPage';

const AdminPage = React.lazy(() => import('/pages/AdminPage'));
const MainPage = React.lazy(() => import('/pages/MainPage'));
// const MainPageClient = React.lazy(() => import('/pages/MainPageClient'));

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPage />,
  },
  // [AppRoutes.ADMIN]: {
  //   path: getRouteAdmin(),
  //   element: <AdminPage />,
  // },
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
  },
};

// TODO: adminRouteConfig отдельно
