import { ICard } from '../model/types/card';
import { useAppAxios } from '/shared/api/axios';
import { TemplateResponse, ErrorResponse } from '/shared/api/type';

export function useCardLiked(link: string) {
  return useAppAxios<TemplateResponse<ICard>, null, ErrorResponse>(
    {
      url: `/marker/${link}/like`,
      method: 'PUT',
    },
    {
      useCache: false,
      manual: true,
    },
  );
}
