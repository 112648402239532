import { create } from 'zustand';
import { MapRef } from 'react-map-gl/maplibre';

export type MapStore = {
  ready: boolean;
  map: null | MapRef;
  initLon?: number;
  initLat?: number;
  initZoom?: number;
  lon: number;
  lat: number;
  zoom: number;
  setReady: () => void;
  setMainMap: (map: MapRef) => void;
  setInitLonLat: (initLon: number, initLat: number) => void;
  setInitZoom: (initZoom: number) => void;
  setLonLat: (lon: number, lat: number) => void;
  setZoom: (zoom: number) => void;
};

export const useMapStore = create<MapStore>()((set, get) => ({
  ready: false,
  map: null,
  lon: 0,
  lat: 0,
  zoom: 1,
  setReady: () => set(() => ({ ready: true })),
  setMainMap: (map) => set({ map }),
  setInitLonLat: (initLon, initLat) => {
    // if (!get().initLat || !get().initLon) {
    // }
    set({ initLat, initLon });
  },
  setInitZoom: (initZoom) => set({ initZoom }),
  setLonLat: (lon, lat) => set({ lon, lat }),
  setZoom: (zoom) => set({ zoom }),
}));
