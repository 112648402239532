import { memo } from 'react';
import {
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl/maplibre';

export const MapControl = memo(() => {
  return (
    <>
      <GeolocateControl position='top-right' />
      <FullscreenControl position='top-right' containerId='map-content' />
      <NavigationControl position='top-right' />
      <ScaleControl position='bottom-right' />
    </>
  );
});
