import { MapMarkerFeature } from '/entities/Marker';

/**
 * @param markerFeatures текущие маркеры
 * @param visitedMarkerFeatures сохраненный список маркеров
 * @returns новый список markerFeatures
 */
export const updateVisited = (
  markerFeatures: MapMarkerFeature[],
  visitedMarkerFeatures: MapMarkerFeature[],
): MapMarkerFeature[] => {
  if (!visitedMarkerFeatures.length || !markerFeatures.length) return markerFeatures;

  return markerFeatures.map((f) => {
    const find = visitedMarkerFeatures.find((v) => v.id === f.id);
    if (find) {
      f.properties.visited = true;
    }
    return f;
  });
};
