import { Image, Stack } from 'react-bootstrap';
import s from './CardCornerLogo.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import logoImg from '/img/logo-name.png';
import { memo } from 'react';

interface CardCornerLogoProps {
  className?: string;
}

export const CardCornerLogo = memo(({ className }: CardCornerLogoProps) => {
  return (
    <Stack direction='horizontal' gap={2} className={classNames(s.CardCornerLogo, {}, [className])}>
      <span className={s.project}>проект разработан</span>
      <Image src={logoImg} className={s.logo}></Image>
    </Stack>
  );
});
