import { MapGeoJSONFeature } from 'react-map-gl/maplibre';

/**
 * @description удаляет дубли фич маркеров по id, удаляет фичи кластеров
 */
export const filterMarkerFeatures = (features: MapGeoJSONFeature[]) => {
  return (
    features
      .filter((f) => !f.properties.cluster && f.id)
      // @ts-ignore
      .sort((a, b) => a.id - b.id)
      .filter((f, i, arr) => (arr[i - 1] ? f.id !== arr[i - 1].id : true))
  );
};
