import { memoize } from 'proxy-memoize';
import { MarkerFeatureStore } from '../store/markerFeatureStore';

export const getMarkerFeatureList = memoize((s: MarkerFeatureStore) => s.markersFeature);
export const getMarkerFeatureSelected = memoize((s: MarkerFeatureStore) => s.selectedMarker);
export const getMarkerFeatureVisitedList = memoize((s: MarkerFeatureStore) => s.visitedMarkers);

export const getFnMarkerFeatureList = memoize((s: MarkerFeatureStore) => s.setMarkersFeature);
export const getFnMarkerFeatureSelected = memoize((s: MarkerFeatureStore) => s.setSelected);
export const getFnMarkerFeatureVisited = memoize((s: MarkerFeatureStore) => s.setVisited);
