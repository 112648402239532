import { ReactNode, RefObject, useCallback, useEffect, useRef } from 'react';
import s from './CardDrawer.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import {
  AnimationProvider,
  useAnimationLibs,
} from '/shared/lib/AnimationProvider/AnimationProvider';
import { Button, Stack } from 'react-bootstrap';
import { Icon } from '/shared/ui/Icon';
import arrowIcon from '/shared/assets/icons/arrow to down.svg?react';

interface CardDrawerProps {
  children: ReactNode;
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

function usePreventScroll(preventScrollRef: RefObject<boolean>) {
  useEffect(() => {
    // @ts-ignore
    const preventScrolling = (e) => {
      if (preventScrollRef.current) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventScrolling, {
      passive: false,
    });
    return () => document.removeEventListener('touchmove', preventScrolling);
  }, []);
}

const height = window.innerHeight;

const CardDrawerContent = (props: CardDrawerProps) => {
  const { className, children, onClose, isOpen } = props;

  const { Spring, Gesture } = useAnimationLibs();
  const [{ y }, api] = Spring.useSpring(() => ({ y: height }));

  const posRef = useRef<'middle' | 'close' | 'open'>('close');
  const preventScrollRef = useRef(false);
  usePreventScroll(preventScrollRef);

  const openDrawer = useCallback(() => {
    posRef.current = 'open';
    api.start({ y: 0, immediate: false });
  }, [api]);

  useEffect(() => {
    if (isOpen) {
      openDrawer();
    }
  }, [api, isOpen, openDrawer]);

  // TODO: среднее положение
  const halfOpenDrawer = useCallback((velocity = 0) => {
    posRef.current = 'middle';
    api.start({
      y: height / 2,
      immediate: false,
      config: { ...Spring.config.stiff, velocity },
    });
  }, []);

  const closeDrawer = useCallback((velocity = 0) => {
    posRef.current = 'close';
    api.start({
      y: height,
      immediate: false,
      config: { ...Spring.config.stiff, velocity },
      onResolve: onClose,
    });
  }, []);

  const closeOnClick = useCallback(() => closeDrawer(), []);

  // const bind = Gesture.useDrag(
  //   ({
  //     last,
  //     velocity: [, vy],
  //     direction: [, dy],
  //     movement: [, my],
  //     xy: [yy],
  //     offset: [oy],
  //     down,
  //     cancel,
  //     event,
  //   }) => {
  //     // if (my < -50) cancel();

  //     // @ts-ignore
  //     // if (down && event.clientY < window.innerHeight / 2) {
  //     //   preventScrollRef.current = true;
  //     //   // } else if (posRef.current === 'middle') {
  //     //   // preventScrollRef.current = true;
  //     // } else {
  //     //   preventScrollRef.current = false;
  //     // }

  //     console.log(
  //       `my=${my}, height=${height}, vy=${vy}, dy=${dy}, yy=${yy}, oy=${oy}`,
  //       event.clientY,
  //       posRef.current,
  //     );

  //     if (last && !preventScrollRef.current) {
  //       //  || (vy > 0.5 && dy > 0)
  //       if (my > 20 && posRef.current === 'middle') {
  //         console.log('middle close');
  //         closeDrawer();
  //       } else {
  //         console.log('nothing', posRef.current);
  //         if (posRef.current === 'middle') {
  //           halfOpenDrawer();
  //         } else if (posRef.current === 'open') {
  //           openDrawer();
  //         } else if (posRef.current === 'close') {
  //           closeDrawer();
  //         }
  //       }
  //     } else {
  //       if (posRef.current === 'middle') {
  //         api.start({ y: my + height, immediate: true });
  //       } else {
  //         api.start({ y: my, immediate: true });
  //       }
  //     }
  //   },
  //   {
  //     from: () => [0, y.get()],
  //     filterTaps: true,
  //     bounds: { top: 0 },
  //     rubberband: true,
  //   },
  // );

  const bind = Gesture.useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      movement: [, my],
      xy: [yy],
      offset: [oy],
      down,
      cancel,
      event,
    }) => {
      if (my < -50) cancel();

      // @ts-ignore
      if (down && event.clientY < window.innerHeight / 2) {
        preventScrollRef.current = true;
        // } else if (posRef.current === 'middle') {
        // preventScrollRef.current = true;
      } else {
        preventScrollRef.current = false;
      }

      // console.log(
      //   `my=${my}, height=${height}, vy=${vy}, dy=${dy}, yy=${yy}, oy=${oy}`,
      //   event.clientY,
      //   posRef.current,
      //   (height / 3) * 2,
      // );

      if (last && !preventScrollRef.current) {
        //  || (vy > 0.5 && dy > 0)
        if (my > 20 && posRef.current === 'middle') {
          closeDrawer();
          // console.log('middle close');
        } else if (my < 0 && posRef.current === 'middle') {
          // preventScrollRef.current = false;
          openDrawer();
          // console.log('open', down);
        } else if (my > 20 && posRef.current === 'open') {
          closeDrawer();
          // console.log('open close');
        } else {
          console.log('nothing', posRef.current);
          if (posRef.current === 'middle') {
            halfOpenDrawer();
          } else if (posRef.current === 'open') {
            openDrawer();
          } else if (posRef.current === 'close') {
            closeDrawer();
          }
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    },
  );

  if (!isOpen) {
    return null;
  }

  // const display = y.to((py) => (py < height ? 'block' : 'none'));

  return (
    <Spring.a.div
      className={classNames(s.CardDrawer, {}, [className])}
      style={{ /** display, bottom: `calc(-100vh + ${height - 100}px)`, */ y }}
      {...bind()}
    >
      <Stack direction='horizontal' className={s.btnWrap}>
        <Button variant='light' className={s.button} onClick={closeOnClick}>
          <Icon Svg={arrowIcon} />
        </Button>
      </Stack>
      {children}
    </Spring.a.div>
  );
};

const DrawerAsync = (props: CardDrawerProps) => {
  const { isLoaded } = useAnimationLibs();

  if (!isLoaded) {
    return null;
  }

  return <CardDrawerContent {...props} />;
};

export const CardDrawer = (props: CardDrawerProps) => (
  <AnimationProvider>
    <DrawerAsync {...props} />
  </AnimationProvider>
);
