import { Card as BCard, Button, Nav, Stack } from 'react-bootstrap';
import s from './Card.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { CardCarousel } from '/entities/CardCarousel/';
import { Icon } from '/shared/ui/Icon';
import shareIcon from '/shared/assets/icons/Union.svg?react';
import { CardMetaInfo } from '/entities/CardMetaInfo/';
import { CardTagsList } from '/entities/CardTagsList/';
import { CardLinks } from '/entities/CardLinks/';
import { CardFooter } from '/entities/CardFooter/';
import { ICard } from '/entities/Card';
import { memo, useCallback } from 'react';
import { Loader } from '/shared/ui/Loader';
import { getRouteWebMarker } from '/shared/const/router';

interface CardProps {
  card?: ICard;
  className?: string;
  onShareClick?: (card: ICard) => void;
  onLikeClick?: (card: ICard) => void;
  liked?: boolean;
  loading?: boolean;
  errorText?: string;
}

export const Card = memo((props: CardProps) => {
  const { className, card, onShareClick, onLikeClick, liked, errorText, loading } = props;

  const onShareClickCb = useCallback(() => onShareClick?.(card!), [card]);
  const onLikeClickCb = useCallback(() => onLikeClick?.(card!), [card]);

  if (errorText || !card) {
    return (
      <BCard className={classNames(s.Card, {}, [className])} border='none' bg='Light'>
        <p>Ошибка при загрузке</p>
      </BCard>
    );
  }

  if (loading) {
    return (
      <BCard className={classNames(s.Card, {}, [className])} border='none' bg='Light'>
        <Loader />
      </BCard>
    );
  }

  return (
    <BCard className={classNames(s.Card, {}, [className])} border='none' bg='Light'>
      <CardCarousel imgSrcs={card.imgSrc} />
      <BCard.Body>
        <Stack direction='horizontal' gap={3} className={classNames('mb-2', {}, [s.header])}>
          <BCard.Title color='primary' className={s.title}>
            {card.name}
          </BCard.Title>
          <Button variant='light' className={s.shareButton} onClick={onShareClickCb}>
            <Icon Svg={shareIcon} />
          </Button>
        </Stack>
        <BCard.Text className={classNames(s.text, {}, ['mb-3'])}>{card.description}</BCard.Text>
        <Stack direction='vertical' gap={2} className='mb-4'>
          <CardMetaInfo
            visits={card.views}
            likes={card.likes}
            liked={liked}
            onLikeClick={onLikeClickCb}
          />
          <CardTagsList tags={card.tags || []} />
        </Stack>
        <CardLinks browserURL={card.webUrl} clientrURL={card.metaClientUrl} ARURL={card.arUrl} />
      </BCard.Body>
      <BCard.Footer className={s.footer}>
        <CardFooter link={card.link} linkSrc={getRouteWebMarker(card.link)} />
      </BCard.Footer>
    </BCard>
  );
});
