import { memo } from 'react';
import { CardCornerLogo } from '/entities/CardCornerLogo/';
import s from './MapCornerLogo.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';

interface MapCornerLogoProps {
  className?: string;
}

export const MapCornerLogo = memo(({ className }: MapCornerLogoProps) => {
  return (
    <div className={classNames(s.MapCornerLogo, {}, [className])}>
      <CardCornerLogo />
    </div>
  );
});
