import { Stack } from 'react-bootstrap';
import s from './CardFooter.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { CardCornerLogo } from '/entities/CardCornerLogo/';

interface CardFooterProps {
  className?: string;
  link?: string;
  linkSrc?: string;
}

export const CardFooter = (props: CardFooterProps) => {
  const { className, link, linkSrc } = props;
  return (
    <Stack className={classNames(s.CardFooter, {}, [className])} direction='horizontal' gap={2}>
      <CardCornerLogo />
      {link && (
        <a href={linkSrc} className={s.link} target='_blank'>
          #{link}
        </a>
      )}
    </Stack>
  );
};
