import s from './CardMetaInfo.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import visitIcon from '/shared/assets/icons/visit.svg?react';
import likeIcon from '/shared/assets/icons/like.svg?react';
import likeFillIcon from '/shared/assets/icons/like filled.svg?react';
import { Icon } from '/shared/ui/Icon';
import { Stack } from 'react-bootstrap';
import { memo } from 'react';
import { IconButton } from '/shared/ui/IconButton/IconButton';

interface CardMetaInfoProps {
  className?: string;
  visits?: number;
  likes?: number;
  liked?: boolean;
  onLikeClick?: () => void;
}

export const CardMetaInfo = memo((props: CardMetaInfoProps) => {
  const { className, visits, likes, liked, onLikeClick } = props;

  return (
    <Stack className={classNames(s.CardMetaInfo, {}, [className])} direction='horizontal' gap={4}>
      <Stack gap={1} direction='horizontal'>
        <Icon Svg={visitIcon} /> {visits ?? 0} посещений
      </Stack>
      <Stack gap={1} direction='horizontal'>
        <IconButton
          onClick={onLikeClick}
          className={s.likeBtn}
          style={{ padding: '8px 2px 8px 8px' }}
          disabled={liked}
        >
          {liked ? <Icon Svg={likeFillIcon} className={s.icon} /> : <Icon Svg={likeIcon} />}
        </IconButton>{' '}
        {likes ?? 0}
      </Stack>
    </Stack>
  );
});
