import { Navbar, Stack } from 'react-bootstrap';
import s from './HeadBar.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { DownloadAppBtn } from '/entities/DownloadAppBtn';
import { HeaderLogo } from '/entities/HeaderLogo';

interface HeadBarProps {
  className?: string;
}

export const HeadBar = ({ className }: HeadBarProps) => {
  //TODO скачать - переход на страницу
  return (
    <Navbar expand='lg' className={classNames(s.HeadBar, {}, [className])}>
      <Stack direction='horizontal' className={s.wrapper}>
        <HeaderLogo />
        <DownloadAppBtn />
        {/* <Nav.Link href='#home'>Home</Nav.Link> */}
        {/* <Nav.Link href='#link'>Link</Nav.Link>
              <NavDropdown title='Dropdown' id='basic-nav-dropdown'>
                <NavDropdown.Item href='#action/3.1'>Action</NavDropdown.Item>
                <NavDropdown.Item href='#action/3.2'>Another action</NavDropdown.Item>
                <NavDropdown.Item href='#action/3.3'>Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#action/3.4'>Separated link</NavDropdown.Item>
              </NavDropdown> */}
      </Stack>
    </Navbar>
  );
};
