import { useCallback, useEffect } from 'react';
import s from './MobileMenuDrawer.module.scss';
import {
  AnimationProvider,
  useAnimationLibs,
} from '/shared/lib/AnimationProvider/AnimationProvider';
import { classNames } from '/shared/lib/classNames/classNames';
import { DownloadAppBtn } from '/entities/DownloadAppBtn';
import { HeaderLogo } from '/entities/HeaderLogo';
import { Button, Image, Stack } from 'react-bootstrap';
import { Icon } from '/shared/ui/Icon';
import closeIcon from '/shared/assets/icons/close.svg?react';

interface MobileMenuDrawerProps {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const height = -300;

export const MobileMenuDrawerContent = (props: MobileMenuDrawerProps) => {
  const { className, onClose, isOpen } = props;

  const { Spring, Gesture } = useAnimationLibs();
  const [{ y }, api] = Spring.useSpring(() => ({ y: height, immediate: true }));

  const openDrawer = useCallback(() => {
    api.start({ y: 0, immediate: false });
  }, [api]);

  useEffect(() => {
    if (isOpen) {
      openDrawer();
    }
  }, [api, isOpen, openDrawer]);

  const closeDrawer = useCallback((velocity = 0) => {
    api.start({
      y: height,
      immediate: false,
      config: { ...Spring.config.stiff, velocity },
      onResolve: onClose,
    });
  }, []);

  const bind = Gesture.useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      movement: [, my],
      xy: [yy],
      offset: [oy],
      cancel,
    }) => {
      if (my > 20) cancel();
      // console.log(`my=${my}, height=${height}, vy=${vy}, dy=${dy}, yy=${yy}, oy=${oy}`);

      if (last) {
        if (my < -30) {
          closeDrawer();
        } else {
          openDrawer();
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      rubberband: true,
    },
  );

  const onCloseClick = useCallback(() => closeDrawer(), []);

  if (!isOpen) {
    return null;
  }

  return (
    <Spring.a.div
      className={classNames(s.MobileMenuDrawer, {}, [className])}
      style={{ y, touchAction: 'none' }}
      {...bind()}
    >
      <Stack direction='vertical' className={s.content}>
        <Stack direction='horizontal' className={s.logo}>
          <HeaderLogo />
          <Button className={s.closeBtn} onClick={onCloseClick}>
            <Icon Svg={closeIcon} />
          </Button>
        </Stack>
        <Stack direction='horizontal' className='mt-4 mb-4' style={{ justifyContent: 'center' }}>
          {/* <DownloadAppBtn /> */}
        </Stack>
        <Stack direction='horizontal' style={{ justifyContent: 'center' }}>
          <Image src='/img/project-logo-bi.png' className={s.logo} draggable='false' />
        </Stack>
      </Stack>
    </Spring.a.div>
  );
};

const DrawerAsync = (props: MobileMenuDrawerProps) => {
  const { isLoaded } = useAnimationLibs();

  if (!isLoaded) {
    return null;
  }

  return <MobileMenuDrawerContent {...props} />;
};

export const MobileMenuDrawer = (props: MobileMenuDrawerProps) => (
  <AnimationProvider>
    <DrawerAsync {...props} />
  </AnimationProvider>
);
