import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  clusterLayerId,
} from '/entities/MapMarkersLayer/';
import {
  GeoJSONSource,
  Layer,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  Source,
  MapRef,
} from 'react-map-gl/maplibre';
import { MapMarkers } from '/features/MapMarkers';
import { MapSourceDataEvent } from 'react-map-gl';
import { useMapStore, getMapReady } from '/widgets/MapGL/';
import { CLUSTER_SOURCE_ID } from '../model/const/sourceId';
import { useGetGeojsonService } from '../model/services/useGetGeojsonService';
import {
  getClusterSourceGeojson,
  getClusterSourceIsLoaded,
  getFnClusterSourceIsLoaded,
} from '../model/selectors/clusterSourceSelectors';
import { useClusterSourceStore } from '../model/store/clusterSourceStore';
import { useMapLoadResources } from '../model/services/useMapLoadResources';

interface MapSourceWithMapProps {
  map: MapRef;
}

const MapSourceWithMap = memo((props: MapSourceWithMapProps) => {
  const { map } = props;

  const [mapFeatures, setMapFeatures] = useState<MapGeoJSONFeature[]>([]); // all features
  const [isFirstMarkers, setIsFirstMarkers] = useState(false);

  const isSourceLoaded = useClusterSourceStore(getClusterSourceIsLoaded);
  const setIsSourceLoaded = useClusterSourceStore(getFnClusterSourceIsLoaded);
  const geojson = useClusterSourceStore(getClusterSourceGeojson);

  useGetGeojsonService();
  useMapLoadResources(map);

  const onMoveEndHandler = useCallback(() => {
    const features = map.querySourceFeatures(CLUSTER_SOURCE_ID);
    if (features) {
      // console.log('onMoveEndHandler, setMapFeatures', features);
      setMapFeatures(features);
      if (!isFirstMarkers) {
        setIsFirstMarkers(true);
      }
    }
  }, [isFirstMarkers]);

  // подписка под конец движение
  useEffect(() => {
    if (isSourceLoaded) {
      // console.log('sub onMoveEnd', map.querySourceFeatures(CLUSTER_SOURCE_ID));
      map.off('moveend', onMoveEndHandler).on('moveend', onMoveEndHandler);
      // onMoveEndHandler();

      return () => {
        map.off('moveend', onMoveEndHandler);
      };
    }
  }, [isSourceLoaded, onMoveEndHandler]);

  const onDataHandler = useCallback(
    (e: MapSourceDataEvent) => {
      if (!isSourceLoaded && e.isSourceLoaded && e.sourceId === CLUSTER_SOURCE_ID) {
        // console.log('setGeoDataLoaded', isSourceLoaded, e, map.getMap()._loaded);
        // setGeoDataLoaded(true);
        setIsSourceLoaded(true);
      }
    },
    [isSourceLoaded],
  );

  // показывает маркеры на старте
  const onLoadHandler = useCallback(() => {
    // console.log('idle');
    onMoveEndHandler();
  }, []);

  // подписка под idle
  useEffect(() => {
    if (isSourceLoaded && !isFirstMarkers) {
      // console.log('on idle effect');
      map.on('idle', onLoadHandler);
      return () => {
        map.off('idle', onLoadHandler);
      };
    }
  }, [isSourceLoaded, onLoadHandler, isFirstMarkers]);

  // отписка от idle
  useEffect(() => {
    if (isFirstMarkers) {
      // console.log('off idle effect');
      map.off('idle', onLoadHandler);
    }
  }, [isFirstMarkers]);

  // подписка под загрузку geojson
  useEffect(() => {
    // console.log('sub onDataHandler');
    map.off('sourcedata', onDataHandler).on('sourcedata', onDataHandler);

    return () => {
      map.off('sourcedata', onDataHandler);
    };
  }, [onDataHandler]);

  const onClickCluster = useCallback((event: MapLayerMouseEvent) => {
    if (!event || !event.features?.[0]) return;

    const feature = event.features[0];
    const clusterId = feature.properties.cluster_id;

    if (feature.properties.cluster) {
      // cluster
      const source = map.getSource(CLUSTER_SOURCE_ID) as GeoJSONSource;

      source?.getClusterExpansionZoom(clusterId).then((zoom) => {
        if (zoom) {
          map.easeTo({
            // @ts-ignore
            center: feature.geometry.coordinates, // TODO
            zoom,
            duration: 400,
          });
        }
      });
    }
  }, []);

  // подписка под клик на кластер
  useEffect(() => {
    map.off('click', clusterLayerId, onClickCluster).on('click', clusterLayerId, onClickCluster);
    return () => {
      map.off('click', clusterLayerId, onClickCluster);
    };
  }, [onClickCluster]);

  return (
    <Source
      id={CLUSTER_SOURCE_ID}
      type='geojson'
      data={geojson}
      // data={import.meta.env.VITE_GEOJSON}
      cluster
      clusterMaxZoom={14}
      clusterRadius={50}
    >
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />

      <MapMarkers mapFeatures={mapFeatures} />
    </Source>
  );
});

export const MapSource = memo(({ map }: { map: MapRef | null | undefined }) => {
  const mapReady = useMapStore(getMapReady);

  return !(map && mapReady) ? null : <MapSourceWithMap map={map} />;
});
