import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { MapMarkerFeature, Marker, MarkerEvent } from '/entities/Marker';
import { MapGeoJSONFeature } from 'react-map-gl/maplibre';
import { useMarkerFeatureStore } from '../model/store/markerFeatureStore';
import {
  getFnMarkerFeatureList,
  getFnMarkerFeatureSelected,
  getFnMarkerFeatureVisited,
  getMarkerFeatureList,
  getMarkerFeatureSelected,
  getMarkerFeatureVisitedList,
} from '../model/selectors/markerFeature';
import { filterMarkerFeatures } from '../lib/filterMarkerFeatures';
import { updateMarkerFeatures } from '../lib/updateMarkerFeatures';
import { updateVisited } from '../lib/updateVisited';
import { useNavigate } from 'react-router-dom';
import { getRouteWebMarker } from '/shared/const/router';

interface MapMarkersProps {
  mapFeatures: MapGeoJSONFeature[] | null;
}

export const MapMarkers = memo((props: MapMarkersProps) => {
  const { mapFeatures } = props;

  const navigate = useNavigate();

  const markersFeature = useMarkerFeatureStore(getMarkerFeatureList);
  const selectedMarker = useMarkerFeatureStore(getMarkerFeatureSelected);
  const visitedMarkers = useMarkerFeatureStore(getMarkerFeatureVisitedList);
  const setSelected = useMarkerFeatureStore(getFnMarkerFeatureSelected);
  const setMarkerFeature = useMarkerFeatureStore(getFnMarkerFeatureList);
  const setVisited = useMarkerFeatureStore(getFnMarkerFeatureVisited);

  useEffect(() => {
    if (mapFeatures) {
      // console.log('MapMarkers exec', isSourceLoaded, mapFeatures);
      const filteredMapMarkers = filterMarkerFeatures(mapFeatures) as MapMarkerFeature[];
      const updateValue = updateMarkerFeatures(markersFeature, filteredMapMarkers);
      if (updateValue) {
        const visitedUpdateValue = updateVisited(updateValue, visitedMarkers);
        // console.log('setMarkerFeature');
        setMarkerFeature(visitedUpdateValue);
      }
    }
  }, [mapFeatures]);

  const onMarkerClick = useCallback(
    (marker: MapMarkerFeature) => (e: MarkerEvent) => {
      // open
      setSelected(marker);
      setVisited(marker);
      // console.log('open', marker);
    },
    [selectedMarker],
  );

  useEffect(() => {
    if (selectedMarker) {
      navigate(getRouteWebMarker(`${selectedMarker.properties.link}`));
    }
  }, [selectedMarker]);

  const markers = useMemo(() => {
    return markersFeature.map((f) => (
      <Marker
        key={f.id}
        latitude={f.properties.lat}
        longitude={f.properties.lon}
        selected={f.id === selectedMarker?.id}
        onClick={onMarkerClick(f)}
        visited={f.properties.visited}
      />
    ));
  }, [onMarkerClick, markersFeature]);

  return markers;
});
