import {
  ICON_FILL_COLOR,
  ICON_FILL_COLOR_SELECTED,
  ICON_FILL_COLOR_VISITED,
} from '../model/const/iconColors';

export const setIconColor = (isSelected: boolean, isVisited: boolean) => {
  if (isSelected) return ICON_FILL_COLOR_SELECTED;
  if (isVisited) return ICON_FILL_COLOR_VISITED;
  return ICON_FILL_COLOR;
};
