import { LayerProps } from 'react-map-gl/maplibre';
import { clusterCountLayerId, clusterLayerId, unclusteredPointLayerId } from '../const/layersId';

export const clusterLayer: LayerProps = {
  id: clusterLayerId,
  type: 'circle',
  source: 'points',
  filter: ['has', 'point_count'],
  paint: {
    // 'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    // 'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    'circle-color': '#af84ff',
    'circle-radius': 15,
    // 'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 25, 15],
  },
};

export const clusterCountLayer: LayerProps = {
  id: clusterCountLayerId,
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Noto Sans Regular'],
    'text-size': 12,
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: unclusteredPointLayerId,
  type: 'symbol',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'none',
    // 'icon-image': 'location',
    // 'icon-size': 0.7,
    // 'icon-size': ['number', ['get', 'size']],
    // 'icon-overlap': 'always',
    // 'icon-color': '#7C6BFF',
    // 'icon-size': ['case', ['has', 'selected'], 2, 1],
    // 'icon-size': ['step', ['get', 'lon'], 0.5, 37.473698, 0.9, 37.504063, 1],
  },
  paint: {
    // 'icon-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.7, 1],
    // 'icon-color': '#ff0000',
    // 'icon-halo-color': '#ff0000',
    // 'icon-halo-width': 4,
  },
};
