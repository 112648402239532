import { useEffect } from 'react';
import { useGetCardExtraInfo } from '/entities/Card';
import { useCardStore } from '../store/cardStore';
import { getFnSetCardExtraInfo } from '../selectors/card';

export const useCardExtraInfoService = (link: string) => {
  const setExtraInfo = useCardStore(getFnSetCardExtraInfo);
  const [{ data }, fetchExtraInfo] = useGetCardExtraInfo(link, { manual: true, useCache: false });

  useEffect(() => {
    if (data?.data) {
      setExtraInfo(data.data);
    }
  }, [data?.data]);

  return fetchExtraInfo;
};
