import React from 'react';
import { classNames } from '/shared/lib/classNames/classNames';
import s from './Icon.module.scss';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  Svg: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

export const Icon = (props: IconProps) => {
  const { className, Svg, ...other } = props;

  return <Svg className={classNames(s.Icon, {}, [className])} {...other} />;
};
