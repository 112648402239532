import React from 'react';
import s from './MainPage.module.scss';
import { Outlet } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import { MapGL } from '/widgets/MapGL';

const MainPage = () => {
  return (
    <main className={s.MainPage}>
      <MapProvider>
        <MapGL />
        <Outlet />
      </MapProvider>
    </main>
  );
};

export default MainPage;
