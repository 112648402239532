import { StyleSpecification } from 'maplibre-gl';
import { layers_vizGrayscale } from '../style/vizGrayscale';

export const mapStyle: StyleSpecification = {
  version: 8,
  sources: {
    protomaps: {
      type: 'vector',
      url: 'pmtiles://' + import.meta.env.VITE_PMTILES,
    },
  },
  // @ts-ignore // TODO
  layers: [...layers_vizGrayscale], //  ...clusterLayers
  sprite: 'https://map.cubemeta.ru/basemaps-assets/sprites/v3/grayscale',
  glyphs: 'https://map.cubemeta.ru/basemaps-assets/fonts/{fontstack}/{range}.pbf',
};
