import { useEffect } from 'react';
import { getCard, useCardLiked } from '/entities/Card';
import { useCardStore } from '../store/cardStore';
import { getFnSetCard } from '../selectors/card';

export const useCardLikedService = (link: string) => {
  const setCard = useCardStore(getFnSetCard);
  const [{ data }, fetchLike] = useCardLiked(link);

  useEffect(() => {
    if (data?.data) {
      setCard(data.data);
    }
  }, [data?.data]);

  return fetchLike;
};
