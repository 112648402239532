import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SideBar } from '/entities/SideBar/';
import { isMobile } from 'react-device-detect';
import { CardDrawer } from '/shared/ui/CardDrawer/';
import { useMarkerFeatureStore } from '/features/MapMarkers/';
import { getRouteWebMarker } from '/shared/const/router';
import { CardFeature } from '/features/CardFeature';
import { getFnMarkerFeatureSelected } from '/features/MapMarkers/';

export const CardSidebar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const setSelected = useMarkerFeatureStore(getFnMarkerFeatureSelected);

  if (!id) {
    return null;
  }

  const onCloseMob = useCallback(() => {
    setIsOpen(false);
    navigate('../');
    setSelected(null);
  }, []);

  if (isMobile) {
    return (
      <CardDrawer isOpen={isOpen} onClose={onCloseMob}>
        <CardFeature link={id} />
      </CardDrawer>
    );
  }

  return (
    <SideBar onClose={onCloseMob}>
      <CardFeature link={id} />
    </SideBar>
  );
};

// export default CardSidebarFeature;
