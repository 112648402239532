import { Button } from 'react-bootstrap';
import s from './MobileHeader.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { Icon } from '/shared/ui/Icon';
import burgerIcon from '/shared/assets/icons/burger.svg?react';
import { memo, useCallback, useState } from 'react';
import { MobileMenuDrawer } from '/entities/MobileMenuDrawer/';

interface MobileHeaderProps {
  className?: string;
}

export const MobileHeader = memo(({ className }: MobileHeaderProps) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const onMenuButtonClick = useCallback(() => setIsOpenDrawer(true), []);
  const onCloseClick = useCallback(() => setIsOpenDrawer(false), []);

  return (
    <div className={classNames(s.MobileHeader, {}, [className])}>
      <Button className={s.menuButton} onClick={onMenuButtonClick}>
        <Icon Svg={burgerIcon} />
      </Button>
      <MobileMenuDrawer isOpen={isOpenDrawer} onClose={onCloseClick} />
    </div>
  );
});
