import { useEffect } from 'react';
import { MapRef } from '/widgets/MapGL';
import { resourcesToLoad } from '../const/resourcesToLoad';

export const useMapLoadResources = (map: MapRef) => {
  useEffect(() => {
    resourcesToLoad.forEach((resource) => {
      map.loadImage(resource.file).then((res) => {
        try {
          if (!map.hasImage(resource.name)) {
            map.addImage(resource.name, res.data);
          }
        } catch (error) {
          console.log(error);
        }
      });
    });
  }, []);
};
