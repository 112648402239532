import { memo, useMemo } from 'react';
import { Carousel as BCarousel, Image } from 'react-bootstrap';
import arrowLeft from '/shared/assets/icons/arrow left.svg?react';
import arrowRight from '/shared/assets/icons/arrow right.svg?react';
import { Icon } from '/shared/ui/Icon';
import { ImgSrcItem } from '../model/type/ImgSrcItem';
import { API_URL } from '/shared/api/rtkApi';
import s from './CardCarousel.module.scss';

interface CardCarouselProps {
  imgSrcs?: ImgSrcItem[];
  className?: string;
}

export const CardCarousel = memo((props: CardCarouselProps) => {
  const { className, imgSrcs } = props;

  const defaultCar = useMemo(
    () => (
      <BCarousel
        className={className}
        controls
        touch={false}
        indicators
        prevIcon={<Icon Svg={arrowLeft} />}
        nextIcon={<Icon Svg={arrowRight} />}
      >
        <BCarousel.Item key='default'>
          <Image src='/img/customImg.jpg' draggable='false' className={s.img} height='200px' />
        </BCarousel.Item>
      </BCarousel>
    ),
    [],
  );

  if (!imgSrcs?.length) {
    return defaultCar;
  }

  return (
    <BCarousel
      className={className}
      controls
      touch
      indicators
      prevIcon={<Icon Svg={arrowLeft} />}
      nextIcon={<Icon Svg={arrowRight} />}
    >
      {imgSrcs?.map((src, i) => (
        <BCarousel.Item key={`${src.name}_${i}`}>
          <Image
            src={`${API_URL}/file/load/${src.name}`}
            draggable='false'
            className={s.img}
            height='200px'
          />
        </BCarousel.Item>
      ))}{' '}
    </BCarousel>
  );
});
