import { memoize } from 'proxy-memoize';
import { CardStore } from '../store/cardStore';

export const getCard = memoize((s: CardStore) => s.card);
export const getCardLoading = memoize((s: CardStore) => s.loading);
export const getCardIsError = memoize((s: CardStore) => s.error);
export const getCardErrorText = memoize((s: CardStore) => s.errorText);
export const getCardExtraInfo = memoize((s: CardStore) => s.extraInfo);

export const getFnSetCard = memoize((s: CardStore) => s.setCard);
export const getFnSetCardIsError = memoize((s: CardStore) => s.setError);
export const getFnSetCardErrorText = memoize((s: CardStore) => s.setErrorText);
export const getFnSetCardloading = memoize((s: CardStore) => s.setLoading);
export const getFnSetCardExtraInfo = memoize((s: CardStore) => s.setExtraInfo);
