import { create } from 'zustand';
import { CardExtraInfo, ICard } from '/entities/Card';

export type CardStore = {
  card: ICard | null;
  loading: boolean;
  error: boolean;
  errorText: string | undefined;
  extraInfo: CardExtraInfo | null;
  setCard: (card: ICard) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: boolean) => void;
  setErrorText: (errorText: string | undefined) => void;
  setExtraInfo: (extraInfo: CardExtraInfo | null) => void;
};

export const useCardStore = create<CardStore>()((set) => ({
  card: null,
  loading: false,
  error: false,
  errorText: undefined,
  extraInfo: null,
  setCard: (card: ICard | null) => set(() => ({ card })),
  setLoading: (loading: boolean) => set(() => ({ loading })),
  setError: (error: boolean) => set(() => ({ error })),
  setErrorText: (errorText: string | undefined) => set(() => ({ errorText })),
  setExtraInfo: (extraInfo: CardExtraInfo | null) => set(() => ({ extraInfo })),
}));
